<template>
  <div class="relative">
    <table class="w-full">
      <thead>
        <tr>
          <td>
            <div class="h-[15mm]">&nbsp;</div>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <div class="pt-[10mm]">
              <slot />
            </div>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td>
            <div class="h-[20mm]">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>

    <div class="fixed top-0 flex w-full items-center justify-between">
      <app-image
        alt="Pollen logo"
        class="h-[10mm]"
        src="/images/logo_full_color.svg"
      />

      <div><slot name="header-append" /></div>
    </div>

    <p
      class="fixed bottom-0 w-full max-w-none text-center text-[10px] leading-none text-subtle"
    >
      POLLEN - 1 B avenue Amélie 92320 CHATILLON SIRET n°95115827800014 - TVA
      Intracommunautaire : FR44951158278<br />
      Organisme de formation enregistré sous le numéro 11922636792 auprès du
      préfet de région d’Île-de-France.<br />
      Cet enregistrement ne vaut pas agrément de l’État.
    </p>
  </div>
</template>
